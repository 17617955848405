export const SeniorityFilterDataModified = [
    {
        title: 'Team Lead',
        code: 'team_lead',
    },
    {
        title: 'Junior',
        code: 'junior'
    },
    {
        title: 'Intern',
        code: 'intern'
    },
    {
        title: 'Senior',
        code: 'senior'
    }
] 