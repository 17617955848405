export let WorkTypeCheckboxData = [
    {
        id: 'hybrid',
        type: 'hybrid',
    },
    {
        id: 'office',
        type: 'office',
    },
    {
        id: 'remote',
        type: 'remote',
    },
]