export const WorkTypeFilterDataModified = [
    {
        code: 'hybrid',
        title : 'Hybrid',
    },
    {
        code: 'office',
        title : 'Office'
    },
    {
        code: 'remote',
        title : 'Remote'
    }
]